export default {
  calender: (params) => `timesheet/calender?${buildQueryParams(params)}`,
  projects: "timesheet/project",
  period: (month) => `timesheet/period?name=${month}`,
  headers: (month) => `timesheet/header?month=${month}`,
  line: "timesheet/line",
  lines: (params) => `timesheet/line?${buildQueryParams(params)}`,
  sendForApproval: "timesheet/approval/send",
  cancelApproval: "timesheet/approval/cancel",
  timesheetReport: "timesheet/report",
  approvalEntries: (params) =>
    `approvals/approvalEntries?${buildQueryParams(params)}`,
  isAppliedLeave: "timesheet/isAppliedLeave",
  reOpenTimeSheet: "timesheet/reOpenTimeSheet",
  deleteEntry: `timesheet/deleteEntry`,
  dimensions: "timesheet/dimensions",
};

function buildQueryParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
